



































































.sprite
  position: absolute
  left: 0
  top: 0
  width: 0
  height: 0
  border: 0
  padding: 0
  margin: 0
  white-space: nowrap
  -webkit-clip-path: inset(100%)
  clip-path: inset(100%)
  clip: rect(0 0 0 0)

#svg-detective-hours
  fill: var(--color-text-primary)

#svg-navbar-brand
  fill: var(--color-text-primary)

#svg-octoprint-logo
  .tone-1
    fill: var(--color-icon-tunneling-1)
  .tone-2
    fill: var(--color-icon-tunneling-2)

#svg-video-placeholder
  fill: rgb(255 255 255 / .3)
